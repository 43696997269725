'use client';

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getCountryFromCookie } from '../../helpers/countries';

// Компонент, який віддає увесь конент під час server-side рендерингу
// А потім заховує його для користувача, якщо країна користувача не присутня в масиві countries
export const ForCountries = ({ children, countries = [], exclude }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const country = getCountryFromCookie();

        if (exclude) {
            setVisible(!countries.includes(country));
            return;
        }

        setVisible(countries.length === 0 || countries.includes(country));
    }, [countries]);

    return visible ? children : <></>;
};

ForCountries.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.string),
};

export const UA = ({ children }) => <ForCountries countries={['UA']}>{children}</ForCountries>;
export const MX = ({ children }) => <ForCountries countries={['MX']}>{children}</ForCountries>;
export const KZ = ({ children }) => <ForCountries countries={['KZ']}>{children}</ForCountries>;
