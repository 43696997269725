'use client';

import { useEffect } from 'react';

export default function BootstrapCollapse() {
    useEffect(() => {
        /* eslint-disable-next-line wrap-iife */
        (async function callImmediately() {
            await import('bootstrap/js/dist/collapse');
        })();
    }, []);
}
