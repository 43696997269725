'use client';

import React, { useState, useEffect } from 'react';
import { getCookie } from 'helpers/cookies';
import A from 'components/UI/A.client';

export default function HeroCTA({ ctaLink, ctaLinkRegistered, ctaText }) {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        setAccount(getCookie('account_url'));
    }, []);

    let link = ctaLink ?? '/signup';

    /* eslint-disable no-restricted-globals */
    if (ctaLinkRegistered && account) {
        link = `https://${account}.${location.host}${ctaLinkRegistered}`;
    }

    return (
        <A href={link} className="btn btn-primary btn-lg me-sm-4 d-sm-inline-block d-block">
            {ctaText}
        </A>
    );
}
