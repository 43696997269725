'use client';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { groupItemsByDivider } from '../../helpers/markdown';

export default function TabbedContent({ children, variant = 'pills', maxWidth, center }) {
    const items = groupItemsByDivider(children).map(([first, ...elements]) => ({
        buttonTitle: first.props.children,
        children: elements,
    }));

    return (
        <div style={{ maxWidth }} className={`${center && 'mx-auto'} mb-5`}>
            <Tabs
                id="tabbed-content"
                defaultActiveKey={0}
                className={`mb-4 ${center && 'justify-content-center'}`}
                variant={variant}
                transition={false}
            >
                {items.map((item, index) => (
                    <Tab eventKey={index} title={item.buttonTitle} key={index} className={center && 'text-center'}>
                        {item.children}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}
