'use client';

export const ModalLayer = (props) => (
    <div
        className={'modal fade'}
        tabIndex={-1}
        aria-hidden="true"
        id={props?.id}
    >
        {props.children}
    </div>
);

export const ModalDialog = ({ title, children, footer, onClose }) => (
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title mt-0">{title}</h5>
                <button type="button" className="btn-close" onClick={onClose}
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer justify-content-between">{footer}</div>
        </div>
    </div>
);

const Modal = (props) => (
    <ModalLayer {...props}>
        <ModalDialog {...props}>
            {props.children}
        </ModalDialog>
    </ModalLayer>
);

export default Modal;
