'use client';

import { useTranslations } from 'next-intl';

import PricingMobileRowValue from './PricingMobileRowValue';
import PopoverLink from '../../../UI/PopoverLink';

const FEATURE_SUPPORT_TRANSLATE_KEYS = {
    email: 'site.pricing.featureSupportByEmail',
    phone: 'site.pricing.featureSupportByPhone',
    chat: 'site.pricing.featureSupportByChat',
};

function PricingMobileRow({ feature }) {
    const t = useTranslations('content/pricing');
    const { key, value, title, description, link = {} } = feature;

    let correctedValue = value;
    let featureInclude = '';

    if (key === 'support') {
        correctedValue = true;
        featureInclude = String(value);

        if (Array.isArray(value)) {
            featureInclude = value
                .map((name) => t(FEATURE_SUPPORT_TRANSLATE_KEYS[name] ?? name))
                .join(', ');
        }
    }

    function renderRow() {
        return (
            <span className='fs-6'>
                <PricingMobileRowValue>{correctedValue}</PricingMobileRowValue>
                &nbsp;
                {title && t(title)}
                {Boolean(featureInclude) && (
                    <>: {featureInclude}</>
                )}
            </span>
        );
    }

    return (
        <div className='mb-2'>
            {description
                ? (
                    <PopoverLink
                        text={renderRow()}
                        message={description && t(description)}
                        triggerEvent="click"
                        rootClose
                        styles="text-start"
                        stylesIcon="fs-6"
                        link={{
                            url: link.url,
                            title: link.title && t(link.title),
                        }}
                    />
                )
                : renderRow()
            }
        </div>
    );
}

export default PricingMobileRow;
