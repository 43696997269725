'use client';

import { useState, useEffect } from 'react';
import { getCookie } from '../helpers/cookies';

export default function useAccount() {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        setAccount(getCookie('account_url'));
    }, []);

    return account;
}
