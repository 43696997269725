import { useTranslations } from 'next-intl';
import IconInline from 'components/icons/IconInline';

const FeatureTooltip = ({ feature, className }) => {
    const t = useTranslations('content/pricing-new');

    const tooltipKey = `feature.${feature.name}Tooltip`;
    const tooltip = t(tooltipKey);
    const tooltipExists = !tooltip.includes(tooltipKey);

    return tooltipExists ? (
        <span
            className={`fs-6 text-secondary opacity-75 ${className}`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title={tooltip}
        >
            <IconInline name="circle-info" />
        </span>
    ) : null;
};

export default FeatureTooltip;
