'use client';

import Image from 'next/legacy/image';
import { useState, useRef } from 'react';
import { useTranslations } from 'next-intl';

import A from 'components/UI/A.client';
import Button from '../../UI/Button';
import { subscribeToBlog } from '../../../helpers/apiClient';
import { openDownloadLink } from '../../../helpers/openExternal';

export default function BlogWhitePaper({ title, description, downloadLink, image }) {
    const t = useTranslations('content/blog');
    const linkRef = useRef(null);

    const [email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);

        const answer = await subscribeToBlog(email);

        if (answer.status === 'success' || answer.error === 'already_subscribed') {
            setIsSuccess(true);
            setIsError(false);

            openDownloadLink(downloadLink);
        }

        setIsError(true);
        setIsSending(false);
    };

    return (
        <div className="bg-cyan rounded-2 p-6 mb-4">
            <div className="row">
                <div className="col-sm-8 col-xs-12">
                    <p className="banner-title mb-3 fw-bold text-center text-sm-start">
                        {title}
                    </p>
                    <p className="fs-4 mb-7 mt-7 mt-sm-0 text-center text-sm-start">
                        {description}
                    </p>

                    {!isSuccess ? (
                        <form className="row white-papers-form" onSubmit={handleSubmit}>
                            <div className="col">
                                <input
                                    className={`form-control ${isError && 'is-invalid'}`}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder={t('site.blogSubscribeFrom.emailPlaceholder')}
                                />
                                <div className="invalid-feedback">
                                    {t('site.blogSubscribeForm.checkEmail')}
                                </div>
                            </div>
                            <div className="col-auto">
                                <Button inProgress={isSending} type="submit">
                                    {t('site.blogWhitePaper.downloadLink')}
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <div className="text-success">
                            {t.rich('site.blogWhitePaper.successfullySubscribed', {
                                A: (children) => <A linkRef={linkRef} href={downloadLink} target="_blank" >{children}</A>,
                            })}
                        </div>
                    )}
                </div>
                <div className="col-sm-4 d-none d-sm-block align-self-center text-center">
                    <Image className="shadow-light" src={image} alt="Blog whitepapper" width={129} height={176} />
                </div>
            </div>
        </div>
    );
}
