'use client';

import { useLocale } from 'next-intl';
import { getPhonesToRender } from 'helpers/phones';
import useCountry from 'hooks/useCountry';
import LinkDropdown from 'components/UI/LinkDropdown';
import contacts from '../Contacts.config.json';

export default function Phones({ itemClasses = '' }) {
    const country = useCountry();
    const locale = useLocale();
    const phones = getPhonesToRender(contacts, locale, country);

    return (
        <LinkDropdown
            id="phonesDropdown"
            items={phones}
            itemClasses={itemClasses}
        />
    );
}
