exports.UA = 'ua';
exports.RU = 'ru';
exports.EN = 'en';
exports.MX = 'mx';
exports.RU_KZ = 'ru-kz';

exports.DEFAULT_DOMAIN = 'joinposter.com';
exports.MX_DOMAIN = 'joinposter.mx';
exports.KZ_DOMAIN = 'joinposter.kz';

exports.DEFAULT_LOCALE = exports.RU;

exports.SUPPORTED_LOCALES = [exports.EN, exports.RU, exports.UA, exports.MX, exports.RU_KZ];

exports.DOMAINS = [
    {
        domain: exports.DEFAULT_DOMAIN,
        defaultLocale: exports.RU,
        locales: [exports.UA, exports.RU, exports.EN],
        env: 'production',
    },
    {
        domain: exports.MX_DOMAIN,
        defaultLocale: exports.MX,
        locales: [exports.MX],
        env: 'production',
    },
    {
        domain: exports.KZ_DOMAIN,
        defaultLocale: exports.RU_KZ,
        locales: [exports.RU_KZ],
        env: 'production',
    },
];

exports.DEFAULT_DOMAINS = [
    {
        domain: 'joinposter.com',
        env: 'production',
    },
    {
        domain: 'localhost:3000',
        env: 'development',
    },
];

exports.LOCALE_STANDARD_NAMES = {
    [exports.UA]: 'uk',
    [exports.MX]: 'es-MX',
    [exports.EN]: 'en-US',
    [exports.RU_KZ]: 'ru-KZ',
};

exports.LOCALE_NAMES = {
    ru: 'Русский',
    uk: 'Українська',
    'en-US': 'English',
    'es-MX': 'Español',
    'ru-KZ': 'Русский (Казахстан)',
};

exports.PREFERRED_COUNTRIES_BY_LANG = {
    en: ['us', 'gb', 'nz', 'au'],
    ru: ['kz', 'uz'],
    ua: ['ua'],
    mx: ['mx', 'es', 'co'],
    'ru-kz': ['kz'],
};

exports.SELECTED_LANG = 'SELECTED_LANG';

exports.MIDDLEWARE_LOCALE = 'x-middleware-request-x-next-intl-locale';

exports.COUNTRY_CODE_COOKIE = 'HTTP_GEOIP_COUNTRY_CODE';
