'use client';

import PropTypes from 'prop-types';
import { Carousel, CarouselItem } from 'react-bootstrap';

import useCountry from '../../../hooks/useCountry';
import { ForCountries } from '../ForCountries';
import { groupItemsByDivider } from '../../../helpers/markdown';

export default function Slider({ children, variant = 'dark', indicators = true }) {
    const country = useCountry();

    const items = groupItemsByDivider(children).filter(
        (elem) => !(elem[0].type === ForCountries && !elem[0].props.countries.includes(country)),
    );

    return (
        <Carousel variant={variant} className="w-100 align-content-center" indicators={indicators}>
            {items.map((item, index) => (
                <CarouselItem key={index}>
                    <div className="d-flex justify-content-center align-content-center"
                        style={{ height: '350px' }}
                    >
                        {item}
                    </div>
                </CarouselItem>
            ))}
        </Carousel>
    );
}
Slider.propTypes = {
    children: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['dark', 'light']),
    indicators: PropTypes.bool,
};
