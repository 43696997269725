'use client';

import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import A from 'components/UI/A.client';
import { SUPPORTED_LOCALES } from '../../constants/i18n';

/**
 * Pagination component
 *
 * @param startIndex
 * @param endIndex
 * @param startTitle
 * @param urlPattern - url pattern with {page} placeholder, like /blog/management/{index}
 * @return {JSX.Element}
 * @constructor
 */
export default function Pagination({ startIndex, endIndex, urlPattern }) {
    const pathname = usePathname();
    const localePattern = new RegExp(`/(${SUPPORTED_LOCALES.join('|')})`);
    const pathnameWithoutLocale = pathname.replace(localePattern, '');

    const pages = [];
    const indexDirection = startIndex < endIndex ? 1 : -1;
    const maxPages = Math.abs(startIndex - endIndex) + 1;
    let activeIndex = 0;

    for (let i = 0; i < maxPages; i += 1) {
        pages.push({
            url: urlPattern.replace('{index}', (startIndex + i * indexDirection).toString()),
            title: 1 + i,
        });

        if (pages[i].url === pathnameWithoutLocale) {
            activeIndex = i;
        }
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className="page-item">
                    <A
                        className="page-link"
                        href={pages[Math.max(0, activeIndex - 1)].url}
                        aria-label="Previous"
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </A>
                </li>

                {pages.map((page, index) => (
                    <li key={index} className={`page-item ${index === activeIndex && 'active'}`}>
                        <A className="page-link" href={page.url}>{page.title}</A>
                    </li>
                ))}

                <li className="page-item">
                    <A
                        className="page-link"
                        href={pages[Math.min(pages.length - 1, activeIndex + 1)].url}
                        aria-label="Next"
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </A>
                </li>
            </ul>
        </nav>
    );
}

Pagination.propTypes = {
    startIndex: PropTypes.number.isRequired,
    endIndex: PropTypes.number.isRequired,
    urlPattern: PropTypes.string.isRequired,
};
