/**
 *
 * @param {string} eventName
 * @param {object} eventData
 * @returns {Promise<any>}
 */
export async function sendAmplitudeEvent({ eventName, eventData }) {
    const formData = new FormData();
    formData.append('eventName', eventName);

    if (eventData) {
        Object.entries(eventData).forEach(([name, value]) => {
            formData.append(name, value);
        });
    }

    return fetch('/api/site/sendAmplitudeEvent', {
        method: 'POST',
        body: formData
    })
        .then(r => r.json())
        .catch(error => {
            console.error('Amplitude event failed', error);
            return Promise.resolve(false);
        });
}

const sendAmplitudeLoginClickForgotUrl = url => sendAmplitudeEvent({
    eventName: 'login/clickForgotUrl',
    eventData: {
        url,
    },
});

const sendAmplitudeUrlReminder = (email, result) => sendAmplitudeEvent({
    eventName: 'login/urlReminder',
    eventData: {
        email,
        result,
    },
});

const sendAmplitudeLoginClick = (url, status) => sendAmplitudeEvent({
    eventName: 'login/clickLogin',
    eventData: {
        url,
        status,
    },
});

export {
    sendAmplitudeLoginClickForgotUrl,
    sendAmplitudeUrlReminder,
    sendAmplitudeLoginClick
}
