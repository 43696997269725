'use client';

import classNames from 'classnames';
import { useLocale } from 'next-intl';

import { getPriceText } from '../../helpers/prices';
import useCountry from '../../hooks/useCountry';

const Price = ({ children, className }) => {
    const country = useCountry();
    const locale = useLocale();
    const priceText = getPriceText(children, country, locale);

    return (
        <span className={classNames('text-nowrap', className)} dangerouslySetInnerHTML={{ __html: priceText }}/>
    );
};

export default Price;
