export const EURO_ZONE = ['AT', 'BE', 'DE', 'GR', 'IE', 'ES', 'IT', 'CY', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'FI', 'FR', 'EE', 'AD', 'VA', 'MC', 'SM', 'ME', 'MF'];

export const CURRENCIES = [
    {
        currency: 'UAH',
        countries: ['UA'],
        defaultForLocales: ['ua', 'ru'],
        exchangeRate: 36,
        symbol: 'грн',
        formatter: '{price} грн',
    },
    {
        currency: 'KZT',
        countries: ['KZ'],
        defaultForLocales: [],
        exchangeRate: 600,
        symbol: '₸',
        formatter: '{price} ₸',
    },
    {
        currency: 'MXN',
        countries: ['MX'],
        defaultForLocales: ['mx'],
        exchangeRate: 18.979,
        symbol: 'MXN',
        formatter: '${price} <small>MXN + IVA</small>',
    },
    {
        currency: 'COP',
        countries: ['CO'],
        defaultForLocales: [],
        exchangeRate: 4000,
        symbol: 'COP',
        formatter: '${price} <small>COP</small>',
    },
    {
        currency: 'PHP',
        countries: ['PH'],
        defaultForLocales: [],
        exchangeRate: 55,
        symbol: '₱',
        formatter: '₱{price}',
    },
    {
        currency: 'EUR',
        countries: EURO_ZONE,
        defaultForLocales: [],
        exchangeRate: 1,
        symbol: '€',
        formatter: '€{price}',
    },
    {
        currency: 'USD',
        countries: '*',
        defaultForLocales: ['en'],
        exchangeRate: 1,
        symbol: '$',
        formatter: '${price}',
    },
];
