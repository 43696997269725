'use client';

import PropTypes from 'prop-types';
import IconInline from 'components/icons/IconInline';

function PricingRowValueBinary({ children }) {
    if (children) {
        return (
            <span className="text-success fs-3">
                <IconInline name="circle-check" />
            </span>
        );
    }

    return null;
}

PricingRowValueBinary.propTypes = {
    children: PropTypes.bool,
};

export default PricingRowValueBinary;
