'use client';

import { useEffect } from 'react';

export default function LinkDropdown({ items, id, itemClasses, onLinkClick = () => {} }) {
    // Подгружаем библиотеку JS для ропдауна
    useEffect(() => { import('bootstrap/js/dist/dropdown'); }, []);

    const isMultipleItems = items.length > 1;

    if (!items.length) return null;

    if (!isMultipleItems) {
        return (
            <a href={items[0].href} id={id} className={`btn p-0 text-start ${itemClasses}`}>
                {items[0].value}
            </a>
        );
    }

    return (
        <div className="dropdown d-inline-block">
            <button
                id={id}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className={`btn p-0 text-start ${itemClasses} ${isMultipleItems && 'dropdown-toggle'}`}
            >
                {items[0].value}
            </button>

            <div className="dropdown-menu p-0" aria-labelledby={id}>
                {items.map((item) => (
                    <div className="dropdown-item p-0" key={`${id}-item-${item.value}`}>
                        <a
                            className={`btn w-100 text-start ${itemClasses}`}
                            href={`${item.href}`}
                            onClick={(e) => { onLinkClick(e, item); }}
                        >
                            {item.value}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}
