'use client';

import { Component, createRef } from 'react';

import withLocale from '../../../hoc/Locale';
import withTranslation from '../../../hoc/Intl';
import AccountUrlInput from '../../form/AccountUrlInput';
import EmailInput from '../../form/EmailInput';
import PhoneInput from '../../form/PhoneInput';
import ReCAPTCHA from '../../form/ReCAPTCHA';
import LegalBlock from '../../pages/signup/LegalBlock';
import Button from '../../UI/Button';
import { saveSignupDataWithDebounce } from '../../../helpers/signupHelper';
import { JOINED_COUNT } from '../../../constants/common';

class SignupOneLine extends Component {
    constructor(props) {
        super(props);

        this.recaptchaRef = createRef();

        this.state = {
            firstSubmitDone: false,
            formValid: false,
            inProgress: false,

            email: { valid: false, value: '', showError: false },
            phone: { valid: false, value: '', showError: false, fullNumber: '', inProgress: false },
            businessName: { valid: false, value: '', showError: false, inProgress: false },
        };
    }

    onChange = async (name, value) => {
        await this.setState({ [name]: value });

        const { businessName, phone, email } = this.state;

        this.setState({ formValid: businessName.valid && phone.valid && email.valid });

        saveSignupDataWithDebounce(businessName, email, phone, this.props.locale);
    };

    onSubmit = async (e) => {
        e.preventDefault();

        const { locale } = this.props;
        const { formValid, phone, businessName, email } = this.state;

        this.setState({ firstSubmitDone: true });

        if (!formValid || phone.inProgress || businessName.inProgress) {
            return;
        }

        this.setState({ inProgress: true });

        // reCaptcha token
        const token = await this.recaptchaRef.current.executeAsync();

        const signupData = JSON.stringify({
            phone: phone.fullNumber,
            phoneRaw: phone.value,
            country: phone.country.toUpperCase(),
            placeName: businessName.value,
            email: email.value,
            url: businessName.accountUrl,
            acceptPrivacyPolicy: 0,
            token,
        });

        const baseUrl = `${locale === 'ru' ? '' : `/${locale}`}/signup`;
        const encodedSignupData = encodeURIComponent(signupData);

        window.location.href = `${baseUrl}?signupData=${encodedSignupData}`;
    };

    render() {
        const { clean } = this.props;
        const { firstSubmitDone, inProgress, formValid, inlineFormActive } = this.state;
        const { t } = this.props.i18n;

        return (
            <div className={clean ? '' : 'exit-container-lg bg-cyan py-8 mb-n7'}>
                <form
                    className={clean ? '' : 'container-lg'}
                    onSubmit={this.onSubmit}
                    onClick={() => !inlineFormActive && this.setState({ inlineFormActive: true })}
                >
                    {!clean && (
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-8 col-xl-6 text-center">
                                <h3>
                                    {t('header')}
                                </h3>
                                <p>
                                    {t('subheader', { joinedCount: JOINED_COUNT })}
                                </p>
                            </div>
                        </div>
                    )}

                    <div className={clean ? '' : 'row justify-content-center'}>
                        <div className={clean ? '' : 'col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 mt-5'}>
                            <EmailInput revalidate={firstSubmitDone} onChange={(change) => this.onChange('email', change)} />

                            <PhoneInput revalidate={firstSubmitDone} onChange={(change) => this.onChange('phone', change)} />

                            <AccountUrlInput revalidate={firstSubmitDone} onChange={(change) => this.onChange('businessName', change)} />

                            <Button
                                type="submit"
                                className="mt-5 px-7 mx-auto d-block"
                                inputBlocked={firstSubmitDone && !formValid}
                                inProgress={inProgress}
                            >
                                {t('buttonStart')}
                            </Button>

                            <ReCAPTCHA ref={this.recaptchaRef} />
                        </div>
                    </div>

                    <LegalBlock inlineFormActive={inlineFormActive} />
                </form>
            </div>
        );
    }
}

export default withLocale(withTranslation(SignupOneLine, 'pages/signup'));
