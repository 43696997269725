import { isObject } from './object';

/**
 * To find out if the object has something on the way and return value
 * otherwise return config argument
 *  
 * @param {*} config 
 * @param {*} key 
 * @returns {*} 
 */
export function getByKey(config, key) {
    if (isObject(config) && config.hasOwnProperty(key)) {
        return config[key];
    }

    return config;
}
