'use client';

import { isValidElement } from 'react';

export default function PricingRowValue({ children }) {
    if (isValidElement(children)) {
        return children;
    }

    return (
        <span className="fs-4">{children}</span>
    );
}
