'use client';

import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import Price from '../Price';
import Button from '../../UI/Button';
import { getBootstrapColumnSize } from '../../../helpers/grid';

const PricingHeader = ({ pricingPlans }) => {
    const [annualToggle, setAnnualToggle] = useState(true);
    const t = useTranslations('content/pricing');

    const columnSize = getBootstrapColumnSize(4, pricingPlans.length);

    return (
        <div className="row container-lg m-auto px-0">
            <div className="col-4 align-items-end d-flex">
                <div className="fs-6">
                    {t('pricing.table.pricingText')}
                    <div className="btn-group text-nowrap d-inline ms-2 fs-6 align-bottom" role="group">
                        <Button
                            className={classNames('btn-sm py-0', { active: annualToggle })}
                            outline
                            onClick={() => setAnnualToggle(true)}
                        >
                            {t('pricing.table.pricingAnnual')}
                        </Button>
                        <Button
                            className={classNames('btn-sm py-0', { active: !annualToggle })}
                            outline
                            onClick={() => setAnnualToggle(false)}
                        >
                            {t('pricing.table.pricingMonth')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="col-8">
                <div className="row">
                    {pricingPlans.map(({ title, target, badgeColor, monthly, annual }, key) => (
                        <div className={`col-${columnSize}`} key={`header-item-${key}`}>
                            <div className="d-flex flex-column h-100">
                                <div>
                                    <span
                                        className="px-2 py-1 rounded-2 fs-3 fw-bold text-white"
                                        style={{ backgroundColor: badgeColor ?? '' }}
                                    >
                                        {title}
                                    </span>
                                </div>
                                <div className="text-secondary fs-6 lh-sm my-2">{t(target)}</div>
                                <div className="mt-auto">
                                    <p className="m-0 lh-sm text-break">
                                        <Price className="fs-4 fw-bold">
                                            {(!annualToggle || !annual) ? monthly : annual}
                                        </Price>
                                        <span className="text-nowrap">/{t('pricing.table.perMonth')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

PricingHeader.propTypes = {
    pricingPlans: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        badgeColor: PropTypes.string,
        monthly: PropTypes.number.isRequired,
        annual: PropTypes.number,
    })).isRequired,
};

export default PricingHeader;
