import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { SUPPORTED_LOCALES } from 'constants/i18n';

const { Link } = createSharedPathnamesNavigation({
    locales: SUPPORTED_LOCALES,
});

const A = Link;

export default A;
