'use client';

import { useState, useEffect } from 'react';
import { useLocale } from 'next-intl';
import { getCookie } from '../helpers/cookies';
import { COUNTRY_CODE_COOKIE, PREFERRED_COUNTRIES_BY_LANG } from '../constants/i18n';

export default function useCountry() {
    const locale = useLocale();

    // Під час server-side рендерингу беремо стандартну країну для мови сайту
    const [country, setCountry] = useState(PREFERRED_COUNTRIES_BY_LANG[locale][0].toUpperCase());

    // Після завантаження на фронті підміняємо на правильну країну
    useEffect(() => {
        const countryFromServer = getCookie(COUNTRY_CODE_COOKIE);
        if (countryFromServer && countryFromServer !== country) {
            setCountry(countryFromServer);
        }
    }, [country]);

    return country;
}
