'use client';

import { useEffect } from 'react';

export default function BootstrapTooltip() {
    useEffect(() => {
        /* eslint-disable-next-line wrap-iife */
        (async function callImmediately() {
            const tooltipModule = await import('bootstrap/js/dist/tooltip');
            const Tooltip = tooltipModule.default;

            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
        })();
    }, []);
}
