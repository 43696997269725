/**
 * @param {*} object
 * @return {boolean}
 */
export function isObject(object) {
    return object !== null && typeof object === 'object';
}

/**
 * Transforms flat object to nested object.
 *
 * @param {Object<string, string>} data - flat object with keys like 'a.b.c' and values
 * @returns {Object} - nested object like { a: { b: { c: value } } }
 */
export function toNestObject(data = {}) {
    return Object.entries(data).reduce((result, [key, value]) => {
        const keys = key.split('.');

        keys.reduce((acc, k, i) => {
            const isLastKey = i === keys.length - 1;

            if (isLastKey) {
                acc[k] = value;
            } else {
                acc[k] = acc[k] || {};
            }

            return acc[k];
        }, result);

        return result;
    }, {});
}
