'use client';

import ReactDOM from 'react-dom';

import { isProduction } from '../helpers/environment';

export default function PreloadResources() {
    if (isProduction()) {
        ReactDOM.preconnect('https://www.googletagmanager.com');
        ReactDOM.preconnect('https://widget.intercom.io');
    }

    return null;
}
