'use client';

import IconInline from 'components/icons/IconInline';
import { useLocale, useTranslations } from 'next-intl';
import contacts from '../Contacts.config.json';
import useCountry from '../../hooks/useCountry';

export default function Messengers() {
    const country = useCountry();
    const locale = useLocale();
    const t = useTranslations('footer');

    const messengers = contacts.messengers[country] || contacts.langMessengers[locale] || [];

    return (
        <>
            {messengers.map((messenger) => (
                <a
                    key={messenger.name}
                    className="btn btn-outline-primary mb-1 fw-bold d-block mx-auto mx-md-0"
                    style={{ width: '235px' }}
                    href={messenger.link} target="_blank"
                    rel="noreferrer"
                >
                    <span className="pe-1">
                        <IconInline name={messenger.icon} />
                    </span>
                    {t('footer.messengerSupport', { name: messenger.name })}
                </a>
            ))}
        </>
    );
}
