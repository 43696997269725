'use client';

import PropTypes from 'prop-types';
import IconInline from 'components/icons/IconInline';

const SUPPORT_BY_EMAIL = 'email';
const SUPPORT_BY_CHAT = 'chat';
const SUPPORT_BY_PHONE = 'phone';

function PricingRowValueSupport({ children }) {
    const supports = new Set(Array.isArray(children) ? children : []);
    const enabledIconClass = 'text-success';
    const disabledIconClass = 'text-black-50 opacity-50';

    const emailSupport = supports.has(SUPPORT_BY_EMAIL);
    const chatSupport = supports.has(SUPPORT_BY_CHAT);
    const phoneSupport = supports.has(SUPPORT_BY_PHONE);

    return (
        <>
            <span className={`fs-3 pe-2 ${emailSupport ? enabledIconClass : disabledIconClass}`}>
                <IconInline name={`circle-envelope-${emailSupport ? 'solid' : 'regular'}`} />
            </span>

            <span className={`fs-3 pe-2 ${chatSupport ? enabledIconClass : disabledIconClass}`}>
                <IconInline name={`comment-${chatSupport ? 'solid' : 'regular'}`} />
            </span>

            <span className={`fs-3 pe-2 ${phoneSupport ? enabledIconClass : disabledIconClass}`}>
                <IconInline name={`circle-phone-${phoneSupport ? 'solid' : 'regular'}`} />
            </span>
        </>
    );
}

PricingRowValueSupport.propTypes = {
    children: PropTypes.arrayOf(PropTypes.string),
};

export default PricingRowValueSupport;
