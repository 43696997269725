'use client';

import useCountry from '../../../hooks/useCountry';
import { ForCountries } from '../ForCountries';
import { getBootstrapColumnSize } from '../../../helpers/grid';
import { groupItemsByDivider } from '../../../helpers/markdown';

const Extensions = ({
    children, maxColumns = 4, minLgColumns = null,
    justify = 'center', scroll = false,
}) => {
    const country = useCountry();

    const selectedExtensions = groupItemsByDivider(children).filter(
        (elem) => !(elem[0].type === ForCountries && !elem[0].props.countries.includes(country)),
    );

    const columnSize = getBootstrapColumnSize(maxColumns, minLgColumns ?? selectedExtensions.length);
    const columnsClasses = columnSize === 2 ? 'col-sm-6 col-md-4 col-lg-3 col-xl-2' : `col-sm-6 col-lg-${columnSize}`;
    const scrollClasses = scroll ? 'flex-nowrap flex-sm-wrap overflow-scroll overflow-sm-auto' : '';

    return (
        <div className={`row justify-content-start justify-content-sm-${justify} ${scrollClasses} fs-6 my-6 ps-2`}>
            {selectedExtensions.map((extension, index) => (
                <div key={`extension-${index}`} className={`${scroll ? 'col-10' : ''} ${columnsClasses} g-4`}>
                    <div className="card border-0 h-100 rounded-3 bg-light">
                        <div className='p-4 h-100'>
                            {extension}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Extensions;
