'use client';

import PropTypes from 'prop-types';

export default function Spinner({ className, small }) {
    return (
        <div className={`spinner-border ${small && 'spinner-border-sm'} ${className}`} role="status" />
    );
}

Spinner.propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool,
};
