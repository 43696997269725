export const convertObjectToUrlParams = (data, parentKey) => {
    const result = new URLSearchParams();

    const processObject = (obj, currentKey) => {
        Object.entries(obj).forEach(([key, value]) => {
            const newKey = currentKey ? `${currentKey}[${key}]` : key;

            if (value !== null && typeof value === 'object') {
                return processObject(value, newKey);
            }

            return result.append(newKey, value);
        });
    };

    processObject(data, parentKey);

    return result;
};
