'use client';

import withCountry from '../../../hoc/Country';
import withTranslation from '../../../hoc/Intl';
import A from '../../UI/A.client';
import { isEuroZone } from '../../../helpers/countries';

// TODO: pass acceptPrivacyPolicy up
const LegalBlock = ({ i18n: { t }, country, formActive, oneLineForm }) => {
    const showGDPR = isEuroZone(country);
    const formSubmitText = oneLineForm ? t('buttonStart') : t('buttonNext');

    return (
        <div className={`row text-secondary small mt-4 ${oneLineForm && 'text-center justify-content-center'}`}>
            <div className={`${oneLineForm ? 'col-md-10 col-lg-8 col-xl-6 text-center' : ''}`}>
                {showGDPR && formActive && (
                    <>
                        <p>{t('termsAndConditionsText1')}</p>

                        <div className="form-check">
                            <input className="form-check-input" id="acceptPrivacyPolicy" type="checkbox" required />
                            <label className="form-check-label" htmlFor="acceptPrivacyPolicy">
                                {t.rich('termsAndConditionsText2', {
                                    link1: (children) => <A href="/terms" target="_blank">{children}</A>,
                                    link2: (children) => <A href="/privacy" target="_blank">{children}</A>,
                                    formSubmitText,
                                })}
                            </label>
                        </div>
                    </>
                )}

                {!showGDPR && formActive && (
                    <p className="mb-0">
                        {t.rich('step1PrivacyPolicyInline', {
                            link1: (children) => <A href="/terms" target="_blank">{children}</A>,
                            link2: (children) => <A href="/privacy" target="_blank">{children}</A>,
                            formSubmitText,
                        })}
                    </p>
                )}
            </div>
        </div>
    );
};

export default withCountry(withTranslation(LegalBlock, 'pages/signup'));
