import { pipe, curry, prop, uniq, sortBy } from 'ramda';

export const filterByCountry = curry((countryCode, list) => list.filter(
    (item) => !item.countries || item.countries.includes(countryCode) || item.countries.includes('*'),
));

const enrichPlansWithPrice = curry((countryCode, plans) => plans.map((plan) => {
    const price = filterByCountry(countryCode, plan.prices)[0];

    return {
        ...plan,
        price,
    };
}));

const enrichPlansWithFeaturesDiff = curry((features, plans) => plans.reduce((acc, plan) => {
    const previousPlan = acc[acc.length - 1];
    const previousPlanFeatures = previousPlan ? previousPlan.features : {};

    const diff = features.filter((feature) => {
        const previousPlanFeatureValue = previousPlanFeatures[feature.name];
        const currentPlanFeatureValue = plan.features[feature.name];

        return previousPlanFeatureValue !== currentPlanFeatureValue;
    }).map((feature) => ({
        ...feature,
        value: plan.features[feature.name],
    }));

    const sorted = sortBy((f) => (f.value === 'Add-on' ? 1 : 0), diff);

    return [...acc, { ...plan, previousPlanTitle: previousPlan?.title, diff: sorted }];
}, []));

export const getPricingPlansForCountry = (pricingConfig, countryCode) => pipe(
    filterByCountry(countryCode),
    enrichPlansWithPrice(countryCode),
    enrichPlansWithFeaturesDiff(pricingConfig.features),
)(pricingConfig.plans);

export const getFeaturesGroupedByModule = (features) => {
    const featuresWithModules = features.map((feature) => {
        const [module] = feature.name.split('.');

        return {
            ...feature,
            module,
        };
    });

    const modules = uniq(featuresWithModules.map(prop('module')));
    const groupedFeatures = modules.map((module) => ({
        module,
        features: featuresWithModules.filter((feature) => feature.module === module),
    }));

    return groupedFeatures;
};
