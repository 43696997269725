'use client';

import dynamic from 'next/dynamic';
import { forwardRef, useEffect, useRef } from 'react';

import useIntersectionObserver from '../../helpers/useInteractionObserver';

const ReCAPTCHAServe = dynamic(() => import('./ReCAPTCHAServe'));

function ReCAPTCHA(props, ref) {
    const container = useRef(null);
    const visible = useIntersectionObserver(container);

    useEffect(() => {
        window.recaptchaOptions = {
            useRecaptchaNet: true,
        };
    }, []);

    return (
        <div ref={container}>
            <style>
                {'.grecaptcha-badge { visibility: hidden; }'}
            </style>

            {visible && (
                <ReCAPTCHAServe forwardRef={ref} />
            )}
        </div>
    );
}

export default forwardRef(ReCAPTCHA);
