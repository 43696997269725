/**
 *
 * @param maxColumns
 * @param itemsCount
 * @returns {number}
 */
export const getBootstrapColumnSize = (maxColumns, itemsCount) => {
    return Math.max(
        Math.ceil(12 / maxColumns),
        Math.round(12 / itemsCount)
    );
}
