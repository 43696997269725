'use client';

import Image from 'next/legacy/image';
import { useEffect, useRef } from 'react';
import { useLocale, useTranslations } from 'next-intl';

import testimonialsData from './Testimonials.config.json';

const Testimonials = () => {
    const carouselRef = useRef(null);
    const carouselInnerRef = useRef(null);
    const locale = useLocale();
    const t = useTranslations('content/testimonials');
    const testimonials = testimonialsData[locale];

    useEffect(() => {
        /* eslint-disable-next-line wrap-iife, func-names */
        (async function () {
            const Carousel = (await import('bootstrap/js/dist/carousel')).default;

            if (carouselRef.current) {
                /* eslint-disable-next-line no-new */
                new Carousel(carouselRef.current);
            }
        })();

        // Тут грязний хак: ставлю в JSON-файлі на перше місце найдовший відгук
        // І ставлю мінімальну висоту діву, щоб не стрибав контент під цією компонентою
        carouselInnerRef.current.style.minHeight = `${carouselInnerRef.current.clientHeight + 1}px`;
    }, []);

    return (
        <div className="text-center my-8">
            <h2>
                {t('testimonials.header')}
            </h2>

            <div className="mt-9" style={{
                fontFamily: 'georgia, serif',
                fontSize: 100,
                color: 'var(--bs-gray-500)',
                lineHeight: 0,
            }}>“</div>

            <div
                ref={carouselRef}
                id="testimonialsCarousel"
                className="carousel slide"
                data-bs-ride="true"
                data-bs-touch="true"
            >
                <div className="carousel-indicators">
                    {testimonials.map((testimonial, index) => (
                        <button
                            key={`testimionial-button-${index}`}
                            type="button"
                            data-bs-target="#testimonialsCarousel"
                            data-bs-slide-to={index}
                            className={`${index === 0 && 'active'}`}
                            aria-current={index === 0}
                            aria-label={`page #${index + 1}`}
                        />
                    ))}
                </div>

                <div
                    className="carousel-inner py-7 px-sm-10 mx-auto"
                    ref={carouselInnerRef}
                >
                    {testimonials.map((testimonial, index) => (
                        <div className={`carousel-item ${index === 0 && 'active'}`} key={`testimonial-${index}`}>
                            <p className="fs-3 mb-7">{testimonial.text}</p>

                            <Image
                                className="rounded-pill"
                                src={`https://joinposter.com${testimonial.picture}`}
                                alt={testimonial.authorName}
                                width={75}
                                height={75}
                            />

                            <div className="small w-75 px-sm-10 mx-auto mb-5">
                                <strong>{testimonial.authorName}</strong>
                                <div dangerouslySetInnerHTML={{ __html: testimonial.authorAbout }}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
