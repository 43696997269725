/* eslint-disable-next-line import/extensions */
import debounce from 'underscore/modules/debounce.js';
import { saveSignupData } from './apiClient';

/**
 * Сохраняем данные из формы по мере ввода. Если посетитель уйдет с сайта,
 * не закончив регистрацию, догоним его письмом
 */
export const saveSignupDataWithDebounce = (businessName, email, phone, locale) => debounce(() => {
    if (!email.value && !phone.value) return;

    saveSignupData({
        lang: locale,
        phone: phone.value,
        email: email.value,
        url: businessName.accountUrl,
        placeName: businessName.value,
    });
}, 1000);

/**
 * Отправляем хит в Google Analytics
 * @param url
 */
export const virtualPageView = (url) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'virtualPageView',
        virtualPageViewPath: url,
    });
};
