'use client';

import useCountry from '../hooks/useCountry';

export default function Country(Component) {
    return function CountryConsumer(props) {
        const country = useCountry();

        return <Component {...props} country={country}/>;
    };
}
