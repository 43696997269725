'use client';

import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

const NameInput = ({ firstSubmit, onChange }) => {
    const [value, setValue] = useState('');
    const [valid, setValid] = useState(false);
    const [hasError, setHasError] = useState(false);
    const t = useTranslations('form');
    const showError = (hasError || firstSubmit) && !valid;

    useEffect(() => {
        onChange({ valid, value });
    }, [valid, value]);

    const onInputChange = ({ target }) => {
        setValue(target.value);
        setValid(Boolean(target.value.toString().length));
        if (!valid) {
            setHasError(true);
        }
    };

    const onBlur = (e) => {
        if (e.target.value) setHasError(true);
    };

    return (
        <div>
            <input
                className={`form-control mt-3 ${showError && 'is-invalid'} ${valid && 'is-valid'}`}
                placeholder={t('name')}
                name="name"
                type="name"
                value={value}
                onInput={onInputChange}
                onBlur={onBlur}
            />

            {showError && (
                <div className="invalid-feedback">
                    {value.toString().length
                        ? t('validationErrorNameIncorrect')
                        : t('validationErrorNameEmpty')
                    }
                </div>
            )}
        </div>
    );
};

export default NameInput;
