import { getCookie } from './cookies';
import { EURO_ZONE } from '../constants/currencies';
import { COUNTRY_CODE_COOKIE, UA } from '../constants/i18n';

/**
 * @param {Headers} headers
 * @param {URLSearchParams} searchParams
 * @return {string}
 */
export const getCountry = (headers, searchParams) => {
    // Дивимось на країну з get-параметру country
    // Потім на країну з хедеру з nginx
    // В останню чергу — fallback до "UA"
    const queryStringCountry = searchParams.get('country');
    const headerCountry = headers.get(COUNTRY_CODE_COOKIE);

    return queryStringCountry ?? headerCountry ?? UA.toUpperCase();
};

/**
 * middleware пишет страну в куку
 */
export const getCountryFromCookie = () => getCookie(COUNTRY_CODE_COOKIE);

export const isEuroZone = (country) => EURO_ZONE.indexOf(country || getCountryFromCookie()) !== -1;
