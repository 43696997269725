'use client';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import A from 'components/UI/A.client';

function PricingTryGuestButton({ className = '', link, text = '' }) {
    return (
        <A href={link} className={classNames('btn btn-primary fs-6', className)}>
            {text}
        </A>
    );
}

PricingTryGuestButton.propTypes = {
    className: PropTypes.string,
    link: PropTypes.string.isRequired,
    text: PropTypes.string,
};

export default PricingTryGuestButton;
