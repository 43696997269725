'use client';

import { useState, useCallback, useEffect } from 'react';

import LoginModalFormAccount from './LoginModalFormAccount';
import LoginModalFormEmail from './LoginModalFormEmail';
import { ModalLayer } from '../../UI/Modal';

export const LOGIN_MODAL_DOM_ID = 'modal_login';

const VIEW_FORM_ACCOUNT = 'form_account';
const VIEW_FORM_EMAIL = 'form_email';

const LoginModal = () => {
    const [currentView, setCurrentView] = useState(VIEW_FORM_ACCOUNT);

    const remindWithEmailHandler = useCallback(() => setCurrentView(VIEW_FORM_EMAIL), []);
    const goBackHandler = useCallback(() => setCurrentView(VIEW_FORM_ACCOUNT), []);

    useEffect(() => {
        import('bootstrap/js/dist/modal');

        document.addEventListener('hidden.bs.modal', goBackHandler);

        return () => document.removeEventListener('hidden.bs.modal', goBackHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ModalLayer id={LOGIN_MODAL_DOM_ID}>
            {
                currentView === VIEW_FORM_EMAIL && <LoginModalFormEmail id={LOGIN_MODAL_DOM_ID} onGoBack={goBackHandler}/>
            }
            {
                currentView === VIEW_FORM_ACCOUNT
                    && <LoginModalFormAccount id={LOGIN_MODAL_DOM_ID} onRemindAccountName={remindWithEmailHandler}/>
            }
        </ModalLayer>
    );
};

export default LoginModal;
