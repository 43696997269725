'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import BootstrapCollapse from 'components/bootstrap/collapse';
import IconInline from 'components/icons/IconInline';
import A from 'components/UI/A.client';
import Price from '../Price';
import useCountry from '../../../hooks/useCountry';
import pricingConfig from './pricing-config/PricingPoster.config.json';
import { getPricingPlansForCountry } from './pricingHelper';
import FeatureTooltip from './FeatureTooltip';

const Pricing = ({ bottomCTA, bottomCTALink }) => {
    const t = useTranslations('content/pricing-new');

    const [annualPayment, setAnnualPayment] = useState(true);
    const country = useCountry();
    const plans = getPricingPlansForCountry(pricingConfig, country);

    return (
        <>
            <BootstrapCollapse/>

            <div className="row mb-4">
                <div className="d-flex justify-content-center justify-content-md-end">
                    <div className="form-check form-switch d-inline-block me-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="switchAnnualPayment"
                            checked={annualPayment}
                            onChange={() => setAnnualPayment(!annualPayment)}
                        />

                        <label className="form-check-label" htmlFor="switchAnnualPayment">Оплата за рік</label>
                    </div>

                    {/* <div className="form-check form-switch d-inline-block">
                        <input className="form-check-input" type="checkbox" role="switch" id="switchRetail"/>
                        <label className="form-check-label" htmlFor="switchRetail">Для магазинів</label>
                    </div> */}
                </div>
            </div>

            <div className={`row row-cols-1 row-cols-sm-2 row-cols-lg-${plans.length}`} id="plansAccordion">
                {plans.map((plan, key) => (
                    <div key={plan.title} className="col mb-4">
                        <div
                            className={`card overflow-hidden h-100 ${plan.popular ? 'shadow' : ''}`}
                            key={plan.title}
                            style={{ borderColor: plan.color }}
                        >
                            <div
                                className="card-header py-4 text-white border-0 d-flex justify-content-between align-items-center"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${plan.title}`}
                                style={{ backgroundColor: plan.color }}
                            >
                                <span className="fs-3 fw-bold flex-grow">
                                    {plan.title}
                                </span>

                                { plan.popular && (
                                    <div>
                                        <small className="small badge text-white fw-bold rounded-pill bg-dark py-1 px-2 bg-opacity-25">
                                            <span className="text-success">&#9679;</span>&nbsp;Популярний
                                        </small>
                                    </div>
                                )}
                            </div>

                            <div
                                className={`card-body d-sm-block collapse ${key === 0 ? 'show' : ''}`}
                                data-bs-parent="#plansAccordion"
                                id={`collapse${plan.title}`}
                            >
                                <p style={{ minHeight: '4.5em' }}>
                                    {t(`description.${plan.title}`)}
                                </p>

                                <p className="fs-3 text-secondary">
                                    <strong className="text-dark">
                                        <Price>{annualPayment ? plan.price.annual : plan.price.monthly}</Price>
                                    </strong>/міс.
                                </p>

                                <A href="/signup" className="btn btn-primary">Спробувати</A>

                                <div className="mt-6 small">
                                    {plan.previousPlanTitle && (
                                        <p className="mb-2 fw-bold">
                                            Все з {plan.previousPlanTitle}, плюс:
                                        </p>
                                    )}

                                    <div>
                                        {plan.diff.map((feature) => feature.important && (
                                            <div key={feature.name} className="d-flex pb-2">
                                                <div className="text-success me-2">
                                                    {feature.value === 'Add-on' ? (
                                                        <div
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            data-bs-title={t('addonTooltip')}
                                                        >
                                                            <IconInline
                                                                name="circle-plus"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <IconInline name="check" />
                                                    )}
                                                </div>

                                                <div className="flex-grow-1">
                                                    {t(`feature.${feature.name}`)}
                                                </div>

                                                <div>
                                                    <FeatureTooltip feature={feature} className="ms-2" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                { bottomCTA && (
                                    <div className="card-footer position-relative bg-white mt-4 p-0 pt-2 d-block d-sm-none">
                                        <a className="small stretched-link" href={bottomCTALink}>
                                            {bottomCTA}
                                        </a>
                                    </div>
                                )}
                            </div>

                            { bottomCTA && (
                                <div className="card-footer position-relative bg-white py-3 d-none d-sm-block">
                                    <a className="small stretched-link" href={bottomCTALink}>
                                        {bottomCTA}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Pricing;
