'use client';

import { useLocale } from 'next-intl';

export default function Locale(Component) {
    return function LocaleConsumer(props) {
        const locale = useLocale();

        return <Component {...props} locale={locale}/>;
    };
}
