'use client';

import { useState } from 'react';
import { useTranslations } from 'next-intl';

import Button from '../../UI/Button';
import { subscribeToBlog } from '../../../helpers/apiClient';

export default function BlogSubscribeForm() {
    const t = useTranslations('content/blog');

    const [email, setEmail] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSending(true);

        const answer = await subscribeToBlog(email);

        if (answer.status === 'success') {
            setIsSuccess(true);
            setIsError(false);
        }
        if (answer.status === 'error' && answer.error === 'already_subscribed') {
            setIsSuccess(true);
        }
        if (answer.status === 'error' && answer.error === 'incorrect_email') {
            setIsError(true);
        }

        setIsSending(false);
    };

    return (
        <form className="bg-light-green py-7 px-5 rounded" onSubmit={handleSubmit}>
            <div className="row">
                <div
                    className="offset-lg-2 col-md-2 offset-md-3 text-md-end d-sm-flex align-items-center justify-content-end">
                </div>
                <div className="col-lg-4 col-md-6">
                    <h4 className="mt-0">
                        {t('site.blogSubscribeForm.title')}
                    </h4>
                    <p>
                        {t('site.blogSubscribeForm.description')}
                    </p>

                    {isSuccess ? (
                        <div className="alert alert-success" role="alert">
                            {t('site.blogSubscribeForm.subscribeSuccessMessage')}
                        </div>
                    ) : (
                        <>
                            <input
                                type="email"
                                className={`form-control mb-4 ${isError && 'is-invalid'}`}
                                id="blogEmail"
                                aria-describedby="emailHelp"
                                placeholder={t('site.blogSubscribeFrom.emailPlaceholder')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value.trim())}
                                required
                            />
                            <div className="invalid-feedback">
                                {t('site.blogSubscribeForm.checkEmail')}
                            </div>
                        </>
                    )}

                    <Button inProgress={isSending} type="submit">
                        {t('site.blogSubscribeForm.subscribeBtn')}
                    </Button>
                </div>
            </div>
        </form>
    );
}
