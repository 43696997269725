'use client';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import PricingRowValue from './PricingRowValue';
import PricingRowValueBinary from './PricingRowValueBinary';
import PricingRowValueSupport from './PricingRowValueSupport';
import PopoverLink from '../../../UI/PopoverLink';
import { getBootstrapColumnSize } from '../../../../helpers/grid';

function PricingRow({ name = '', title = '', description = '', link = {}, pricingPlansValues = [], last = false }) {
    const t = useTranslations('content/pricing');

    const columnSize = getBootstrapColumnSize(4, pricingPlansValues.length);

    function getValuePresenterComponent(value) {
        if (name === 'support') {
            return <PricingRowValueSupport>{value}</PricingRowValueSupport>;
        }

        switch (value) {
        case typeof value === 'boolean':
            return <PricingRowValueBinary>{value}</PricingRowValueBinary>;
        default:
            return <PricingRowValue>{value}</PricingRowValue>;
        }
    }

    return (
        <div className={classNames('row py-3', {
            'border-bottom': !last,
        })}>
            <div className="col-4 fs-6">
                {description
                    ? (
                        <PopoverLink
                            text={title && t(title)}
                            message={description && t(description)}
                            triggerEvent='click'
                            rootClose
                            styles="fs-6"
                            link={{
                                url: link.url,
                                title: link.title && t(link.title),
                            }}
                        />
                    )
                    : (<span>{title && t(title)}</span>)
                }
            </div>
            <div className="col-8">
                <div className="row">
                    {pricingPlansValues.map((value, index) => (
                        <div key={`pricing-pow-${index}`} className={`col-${columnSize}`}>
                            {getValuePresenterComponent(value)}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

PricingRow.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
    }),
    pricingPlansValues: PropTypes.array,
    last: PropTypes.bool,
};

export default PricingRow;
