'use client';

import { Component, createRef } from 'react';

/* eslint-disable-next-line import/extensions */
import debounce from 'underscore/modules/debounce.js';

import withTranslation from '../../hoc/Intl';
import ReCAPTCHA from './ReCAPTCHA';

import { getUrlFromBusinessName } from '../../helpers/apiClient';

class AccountUrlInput extends Component {
    constructor(props) {
        super(props);

        this.accountHelpIcon = createRef();
        this.recaptchaRef = createRef();
        this.state = {
            value: '',
            valid: false,
        };
    }

    async componentDidMount() {
        try {
            const Tooltip = (await import('bootstrap/js/dist/tooltip')).default;
            if (this.accountHelpIcon.current) {
                /* eslint-disable-next-line no-new */
                new Tooltip(this.accountHelpIcon.current);
            }
        } catch (error) {
            console.error('Failed to load the Tooltip module:', error);
        }
    }

    onInputChange = async (e) => {
        const { value } = e.target;

        const valid = value.toString().length >= 2;

        if (!valid) {
            await this.setState({ inProgress: false, accountUrl: '', value });
            return;
        }

        await this.setState({ inProgress: true, value });
        this.getBusinessName();
    };

    /**
     * Запрашиваем транслитерацию урла с бекенда при изменении названия заведения
     */
    getBusinessName = debounce(async () => {
        const { onChange } = this.props;
        const { value } = this.state;
        const recaptchaToken = await this.recaptchaRef.current.executeAsync();
        const response = await getUrlFromBusinessName({ businessName: value, recaptchaToken, abortContext: this });

        if (response.error) {
            this.setState({ inProgress: false, valid: false }, () => {
                /* eslint-disable-next-line no-unused-expressions */
                onChange && onChange(this.state);
            });
            return;
        }

        this.setState({ inProgress: false, accountUrl: response.url, valid: true }, () => {
            // Отправляем изменения в форму выше
            /* eslint-disable-next-line no-unused-expressions */
            onChange && onChange(this.state);
        });
    }, 250);

    /* eslint-disable no-return-assign */
    render() {
        const { inProgress, valid, hasError, value, accountUrl } = this.state;
        const { firstSubmit } = this.props;
        const showError = (hasError || firstSubmit) && !valid;
        const { t } = this.props.i18n;

        return (
            <div>
                <input
                    className={`form-control mt-3 ${!inProgress && valid && 'is-valid'} ${showError && 'is-invalid'}`}
                    type="text"
                    placeholder={t('businessNamePlaceholder')}

                    value={value}
                    onInput={this.onInputChange}
                    onBlur={(e) => {
                        // Когда что-то ввели начинаем показывать ошибки
                        if (e.target.value) {
                            this.setState({ hasError: true });
                            return;
                        }

                        this.setState({ valid: false });
                    }}
                />
                <div className="invalid-feedback">
                    {t('validationErrorBusinessNameEmpty')}
                </div>

                <div className={`text-secondary small mt-2 ${!accountUrl && 'd-none'}`}>
                    <i
                        ref={this.accountHelpIcon}
                        className="btn btn-link p-0 fa-light fa-circle-question"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t('urlHint')}
                    /> {t('yourUrl')}: {accountUrl}.joinposter.com
                </div>

                <ReCAPTCHA ref={this.recaptchaRef} />
            </div>
        );
    }
}

export default withTranslation(AccountUrlInput, 'form');
