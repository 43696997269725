'use client';

import { useTranslations } from 'next-intl';

import Button from '../../UI/Button';
import { ModalDialog } from '../../UI/Modal';
import {
    RESPONSE_EMAIL_NOT_FOUND,
    RESPONSE_ERROR,
    RESPONSE_SUCCESS,
} from './LoginModalConstants';

const LoginModalMessage = ({ responseCode, onButtonClick }) => {
    const t = useTranslations('content/login');
    const { title, body, button } = LoginModalMessage.responseCodeMapping[responseCode || RESPONSE_ERROR];

    return (
        <ModalDialog
            title={t(title)}
            footer={<Button link onClick={onButtonClick}>{t(button)}</Button>}>
            <p>{t(body)}</p>
            <p className="mb-0 fs-6">
                {t('modal.login.remindSupportHint')}
                &nbsp;
                <a href="#" onClick={LoginModalMessage.contactSupport}>
                    {t('modal.login.remindSupportLink')}
                </a>
            </p>
        </ModalDialog>
    );
};

LoginModalMessage.contactSupport = (e) => {
    e.preventDefault();
    /* eslint-disable-next-line no-unused-expressions */
    window?.Intercom && window.Intercom('showNewMessage');
};

LoginModalMessage.responseCodeMapping = {
    [RESPONSE_SUCCESS]: {
        title: 'modal.login.remindSuccessTitle',
        body: 'modal.login.remindSuccessDescription',
        button: 'modal.login.remindSuccessButton',
    },
    [RESPONSE_EMAIL_NOT_FOUND]: {
        title: 'modal.login.remindFailedTitle',
        body: 'modal.login.remindFailedDescription',
        button: 'modal.login.remindFailedButton',
    },
    [RESPONSE_ERROR]: {
        title: 'modal.login.remindErrorTitle',
        body: 'modal.login.remindErrorDescription',
        button: 'modal.login.remindErrorButton',
    },
};

export default LoginModalMessage;
