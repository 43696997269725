export function groupItemsByDivider(items, divider = 'hr') {
    const groupedItems = [[]];
    items.forEach((item) => {
        if (item.type === divider) {
            groupedItems.push([]);
            return;
        }

        groupedItems[groupedItems.length - 1].push(item);
    });

    return groupedItems;
}
