export function openDemoAccount(lang) {
    const demoUrls = {
        ru: 'demo',
        ua: 'tutorial-ua',
        en: 'try-demo',
        pl: 'tutaj',
        mx: 'latam-poster',
    };

    const demoUrl = `https://${demoUrls[lang] ?? demoUrls.en}.joinposter.com/manage/login?demo`;
    window.open(demoUrl, '_blank');
}

export function openDownloadLink(downloadLink) {
    window.open(`${downloadLink}?download=true`, '_blank');
}
