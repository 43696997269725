'use client';

import { Component } from 'react';

import withTranslation from '../../hoc/Intl';

class EmailInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };
    }

    onInputChange = (e) => {
        const { onChange } = this.props;
        const { value } = e.target;

        const emailRegexp = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))'
            + '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$', 'g');

        const valid = emailRegexp.test(String(value).toLowerCase());

        this.setState({ value, valid }, () => {
            // Отправляем изменения в форму выше
            /* eslint-disable-next-line no-unused-expressions */
            onChange && onChange(this.state);
        });
    };

    render() {
        const { valid, value, hasError } = this.state;
        const { firstSubmit } = this.props;
        const showError = (hasError || firstSubmit) && !valid;
        const { t } = this.props.i18n;

        return (
            <div>
                <input
                    className={`form-control mt-3 ${showError && 'is-invalid'} ${valid && 'is-valid'}`}
                    placeholder={t('email')}
                    name="email"
                    type="email"
                    value={value}
                    onInput={this.onInputChange}
                    onBlur={(e) => {
                        // Когда что-то ввели начинаем показываем ошибки
                        if (e.target.value) this.setState({ hasError: true });
                    }}
                />

                <div className="invalid-feedback">
                    {value.toString().length
                        ? t('validationErrorEmailIncorrect')
                        : t('validationErrorEmailEmpty')
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation(EmailInput, 'form');
