'use client';

import { useTranslations } from 'next-intl';

export default function Intl(Component, namespace) {
    return function IntlConsumer(props) {
        const t = useTranslations(namespace);

        return <Component {...props} i18n={{ t }}/>;
    };
}
