'use client';

import Script from 'next/script';
import { useEffect, useCallback } from 'react';

// to solve some unknown problem with hydrate process and next.js v12.1.6
// since next.js v12.2.4 we should use onReady props in Script component to solve this problem
let loadedTally = false;

function TallyForm({ children }) {
    useEffect(() => {
        if (loadedTally) {
            // eslint-disable-next-line no-undef
            globalThis?.Tally?.loadEmbeds();
        }
    }, []);

    const handleLoad = useCallback(() => {
        loadedTally = true;
    }, []);

    return (
        <>
            {children}
            <Script id="tally-embed" src="https://tally.so/widgets/embed.js" onLoad={handleLoad}/>
        </>
    );
}

export default TallyForm;
