'use client';

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import Price from '../../Price';
import PricingMobilePlanFeatures from './PricingMobilePlanFeatures';

const PricingMobile = ({ pricingPlans, features, type = '', tryGuestButton = undefined }) => {
    const t = useTranslations('content/pricing');

    const fedPlans = useMemo(() => pricingPlans.map((pricingPlan, index) => {
        const previousPricingPlan = pricingPlans[index - 1];

        const enabledFeaturesWithValue = features
            .map((feature) => ({
                ...feature,
                value: pricingPlan.features[feature.key],
                existOnPreviousPlan: Boolean(previousPricingPlan)
                    && previousPricingPlan.features[feature.key] === pricingPlan.features[feature.key],
            }))
            .filter((feature) => feature.value);

        return {
            ...pricingPlan,
            previousPricingPlan,
            enabledFeaturesWithValue,
            featuresExistOnPreviousPricingPlan: enabledFeaturesWithValue.filter((feature) => feature.existOnPreviousPlan),
            extraFeaturesFromPreviousPricingPlan: enabledFeaturesWithValue.filter((feature) => !feature.existOnPreviousPlan),
        };
    }), [pricingPlans, features]);

    return (
        <div className="accordion">
            {fedPlans.map((pricingPlan, index) => (
                <div
                    className="accordion-item mt-4 rounded-3"
                    style={{ borderColor: pricingPlan.badgeColor }}
                    key={index}
                >
                    <div className="accordion-header">
                        <div
                            className="p-4 rounded-top text-white"
                            style={{ backgroundColor: pricingPlan.badgeColor }}
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${index}`}
                            aria-expanded="true"
                            aria-controls={`collapse-${index}`}
                        >
                            <div className="fs-3 fw-bold lh-sm">{pricingPlan.title}</div>
                            <div className="row lh-sm">
                                <div className="d-flex justify-content-between align-items-baseline">
                                    <small className="text-white-50 pe-2">{t(pricingPlan.target[type] ?? pricingPlan.target)}</small>

                                    <small className="text-nowrap">
                                        {t('pricing.table.pricingFrom')}&nbsp;
                                        <Price className="fs-3 fw-bold">{pricingPlan.annual}</Price>
                                        <span>/{t('pricing.table.perMonth')}</span>
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        id={`collapse-${index}`}
                        className={classNames('accordion-collapse collapse', { show: index === 0 })}
                    >
                        <div className="accordion-body">
                            <PricingMobilePlanFeatures pricingPlan={pricingPlan} />
                            {Boolean(tryGuestButton) && tryGuestButton}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

PricingMobile.propTypes = {
    pricingPlans: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        features: PropTypes.object,
    })).isRequired,
    features: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string,
    tryGuestButton: PropTypes.node,
};

export default PricingMobile;
