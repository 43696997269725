import {
    DOMAINS,
    DEFAULT_DOMAINS,
    DEFAULT_LOCALE,
    LOCALE_STANDARD_NAMES,
    SELECTED_LANG,
} from '../constants/i18n';
import { isProduction } from './environment';

/**
 * @param {array} domains
 * @returns {array}
 */
export const getDomainsForCurrentEnv = (domains) => domains.filter(({ env }) => env === process.env.NODE_ENV);

export function getDefaultDomain() {
    return DEFAULT_DOMAINS
        .find((domain) => domain.env === process.env.NODE_ENV)
        .domain || 'joinposter.com';
}

/**
 * @param {string} locale
 * @returns {string}
 */
export function discoverDomainByLocale(locale) {
    const domains = getDomainsForCurrentEnv(DOMAINS);
    const domainByLocale = domains.find((item) => item.defaultLocale === locale)
        || domains.find((item) => item.locales.includes(locale));

    return domainByLocale?.domain ?? getDefaultDomain();
}

export function patchHref({ href, locale, currentLocale, withDomain = false, pagesWithCustomUrls }) {
    if (href.startsWith('http://') || href.startsWith('https://')) {
        return href;
    }

    currentLocale = currentLocale || locale;

    // подставляем локализацию для того что бы найти локализованный урл
    let url = pagesWithCustomUrls[href] ? pagesWithCustomUrls[href] : href;

    const domainByLocale = discoverDomainByLocale(locale);

    if (domainByLocale !== getDefaultDomain()) {
        // есть языковой домен для выбраной локали
        url = url === `/${locale}` ? '/' : url.replace(`/${locale}`, '');
    }

    if (currentLocale !== locale || withDomain) {
        // мы сейчас на другой локали и нам нужно отобразить домен в ссылке
        const protocol = isProduction() ? 'https://' : 'http://';
        url = `${protocol}${domainByLocale}${url}`;
    }

    return url;
}

export function patchLocaleName(locale) {
    return LOCALE_STANDARD_NAMES[locale] ? LOCALE_STANDARD_NAMES[locale] : locale;
}

export function localizeReadingTime(minutes, locale) {
    const locales = {
        en: { one: '1 minute', other: '{} minutes' },
        ru: { one: '1 минута', few: '{} минуты', many: '{} минут', other: '{} минут' },
        ua: { one: '1 хвилина', few: '{} хвилини', many: '{} хвилин', other: '{} хвилин' },
        mx: { one: '1 minuto', other: '{} minutos' },
    };

    const rules = {
        en: (n) => (n === 1 ? 'one' : 'other'),
        // eslint-disable-next-line no-nested-ternary
        ru: (n) => (n === 1 ? 'one' : (n > 1 && n < 5) ? 'few' : (n >= 5) ? 'many' : 'other'),
        // eslint-disable-next-line no-nested-ternary
        ua: (n) => (n === 1 ? 'one' : (n > 1 && n < 5) ? 'few' : (n >= 5) ? 'many' : 'other'),
        mx: (n) => (n === 1 ? 'one' : 'other'),
    };

    const rule = rules[locale] || rules.en;
    const key = rule(minutes);
    const pattern = locales[locale][key] || locales[locale].one;

    return pattern.replace('{}', minutes);
}

/**
 * @param {import('next/dist/server/web/spec-extension/cookies').RequestCookies} cookies
 * @return {string|undefined}
 */
export const getSelectedLangCookie = (cookies) => cookies.get(SELECTED_LANG)?.value;

/**
 * Generate locale URL.
 *
 * @param {Array<string>} slug
 * @param {string} locale
 * @return {string}
 */
export function generateLocaleUrl(slug, locale) {
    if (slug.length === 0) {
        return locale !== DEFAULT_LOCALE ? `/${locale}` : '/';
    }

    return locale !== DEFAULT_LOCALE ? `/${locale}/${slug.join('/')}` : `/${slug.join('/')}`;
}

/**
 * @param {string} locale
 * @param {string} url
 * @returns {boolean}
 */
export const hasLocaleInUrl = (locale, url) => {
    const regex = new RegExp(`^/${locale}(/|$)`);
    return regex.test(url);
};
