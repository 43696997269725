'use client';

import dynamic from 'next/dynamic';
import { Suspense, useEffect, useRef, useState } from 'react';
/* eslint-disable-next-line import/extensions */
import union from 'underscore/modules/union.js';
import { useLocale, useTranslations } from 'next-intl';

import useCountry from '../../hooks/useCountry';
import { formatBinotelNumber, getPhoneLength } from '../../helpers/phones';
import useIntersectionObserver from '../../helpers/useInteractionObserver';
import { PREFERRED_COUNTRIES_BY_LANG } from '../../constants/i18n';

import 'react-phone-input-2/lib/bootstrap.css';

const ReactPhoneInput = dynamic(() => import('react-phone-input-2'), { suspense: true });

export default function PhoneInput({ firstSubmit, onChange = () => {} }) {
    const defaultCountry = useCountry();
    const locale = useLocale();
    const t = useTranslations('form');

    // Куча разных стейтов
    const [country, setCountry] = useState(defaultCountry);
    const [fullNumber, setFullNumber] = useState('');
    const [value, setValue] = useState('');
    const [hasError, setHasError] = useState(false);
    const [valid, setValid] = useState(false);
    const showError = (hasError || firstSubmit) && !valid;

    const validNumberLength = getPhoneLength(country.toUpperCase());
    const enteredNumberLength = fullNumber.replace(/[^0-9]/g, '').length;

    const phoneContainer = useRef(null);
    const phoneContainerVisible = useIntersectionObserver(phoneContainer);

    const validationControlClasses = `${showError && 'is-invalid'} ${valid && 'is-valid'}`;

    const onBlur = (isError) => {
        if (value) setHasError(isError);
    };

    useEffect(() => {
        onChange({ country, fullNumber, value, hasError, valid });
    }, [country, fullNumber, value, hasError, valid]);

    return (
        <div ref={phoneContainer}>
            {phoneContainerVisible ? (
                <Suspense fallback={<input type="tel" className="mt-3 form-control" aria-label={t('phone')} />}>
                    <ReactPhoneInput
                        country={country.toLowerCase()}
                        value={value}
                        onChange={async (phone, { countryCode, dialCode }) => {
                            setValue(phone);
                            setCountry(countryCode);

                            /* eslint-disable-next-line no-param-reassign */
                            phone = phone.startsWith(dialCode) ? `+${phone}` : `+${dialCode}${phone}`;

                            const validatedPhone = await formatBinotelNumber(phone);

                            if (!validatedPhone) {
                                setValid(false);
                                setFullNumber('');
                                return;
                            }

                            setValid(true);
                            setFullNumber(validatedPhone);
                        }}

                        className={`mt-3 ${validationControlClasses}`}
                        excludeCountries={['ru', 'by']}
                        preserveOrder={['preferredCountries']}
                        preferredCountries={union([country.toLowerCase()], PREFERRED_COUNTRIES_BY_LANG[locale])}

                        placeholder={t('phone')}
                        inputProps={{
                            'aria-label': t('phone'),
                            id: 'phone',
                            onBlur,
                            className: `form-control override-form-control ps-8 ${validationControlClasses}`,
                        }}
                    />
                    <div className="invalid-feedback">
                        {!value && t('validationErrorPhoneEmpty')}

                        {value && validNumberLength && validNumberLength !== enteredNumberLength && (
                            t('validationErrorPhoneIncorrectDigitsCount', { number: validNumberLength })
                        )}
                        {value && !validNumberLength && t('validationErrorPhoneIncorrect')}
                    </div>
                </Suspense>
            ) : (
                <input type="tel" className="mt-3 form-control" aria-label={t('phone')} />
            )}
        </div>
    );
}
