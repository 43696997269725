'use client';

import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import classNames from 'classnames';

import IconInline from 'components/icons/IconInline';
import A from 'components/UI/A.client';

const PopoverLink = ({
    text,
    message,
    triggerEvent = 'hover',
    rootClose = false,
    placement = 'bottom',
    styles,
    stylesIcon,
    link: { url = null, title = null } = {},
}) => {
    const popover = (
        <Popover>
            <Popover.Body className="p-2">
                <small className="lh-sm">
                    {message}
                    {url && (
                        <A
                            href={url}
                            className="d-block text-decoration-none mt-2"
                        >
                            {title}
                        </A>
                    )}
                </small>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger={triggerEvent}
            placement={placement}
            overlay={popover}
            rootClose={rootClose}
        >
            <button className={classNames('btn btn-link without-hover p-0 text-black', styles)}>
                {text}

                <span className={classNames('text-muted ps-1', stylesIcon)}>
                    <IconInline name="circle-info" />
                </span>
            </button>
        </OverlayTrigger>
    );
};

PopoverLink.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    message: PropTypes.string.isRequired,
    triggerEvent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.oneOf(['hover', 'click', 'focus'])),
    ]),
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    styles: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
    }),
};

export default PopoverLink;
