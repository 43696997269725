'use client';

import { useMemo } from 'react';

import PricingHeader from './PricingHeader';
import pricingConfig from './Pricing.config.json';
import PricingTryGuestButton from './PricingTryGuestButton';
import PricingMobile from './pricingMobile/PricingMobile';
import PricingRow from './pricingRow/PricingRow';
import useAccount from '../../../hooks/useAccount';
import useCountry from '../../../hooks/useCountry';
import { getByKey } from '../../../helpers/config';
import { isObject } from '../../../helpers/object';

const Pricing = ({ type, tryGuestLink, tryGuestLinkText }) => {
    const account = useAccount();
    const country = useCountry();

    const pricingPlans = useMemo(() => pricingConfig.pricingPlans
        .filter((plan) => plan.countries.includes(country) || plan.countries.includes('*'))
        .map((plan) => {
            const matchingPrice = plan.prices.find(
                (price) => price.country.includes(country) || price.country.includes('*'),
            );

            const handledPlan = Object.entries(plan).reduce((normalizedPlan, [key, value]) => {
                if (key === 'features' && isObject(value)) {
                    // eslint-disable-next-line no-param-reassign
                    value = Object.entries(value).reduce((normalizedFeatures, [featuresKey, featuresValue]) => {
                        // eslint-disable-next-line no-param-reassign
                        normalizedFeatures[featuresKey] = getByKey(featuresValue, type);
                        return normalizedFeatures;
                    }, {});
                }

                // eslint-disable-next-line no-param-reassign
                normalizedPlan[key] = getByKey(value, type);
                return normalizedPlan;
            }, {});

            return {
                ...handledPlan,
                monthly: matchingPrice?.monthly,
                annual: matchingPrice?.annual,
            };
        }), [country, type]);

    const features = pricingConfig.features
        .map((feature) => {
            const normalizedFeature = getByKey(feature, type);
            let pricingPlansValues = pricingPlans.map((plan) => plan.features?.[feature.key]);

            if (pricingPlansValues.every((value) => !value)) {
                pricingPlansValues = null;
            }

            return {
                key: feature.key,
                ...normalizedFeature,
                pricingPlansValues,
            };
        })
        .filter((feature) => feature.pricingPlansValues);

    let tryPricingPlanButtonsForGuest = [];
    let tryMobileButtonForGuest;
    if (!account && tryGuestLink) {
        tryMobileButtonForGuest = (
            <PricingTryGuestButton link={tryGuestLink} text={tryGuestLinkText} className="w-100 mt-3" />
        );
        tryPricingPlanButtonsForGuest = new Array(pricingPlans.length)
            .fill(<PricingTryGuestButton link={tryGuestLink} text={tryGuestLinkText} />);
    }

    return (
        <>
            <div className="d-none d-sm-block text-start">
                <div className="pb-3 pt-10 border-bottom bg-white sticky-top exit-container-lg">
                    <PricingHeader
                        pricingPlans={pricingPlans.map((pricingPlan) => ({
                            title: pricingPlan.title,
                            target: pricingPlan.target[type] ?? pricingPlan.target,
                            badgeColor: pricingPlan.badgeColor,
                            monthly: pricingPlan.monthly,
                            annual: pricingPlan.annual,
                        }))}
                    />
                </div>
                {features.map((feature, index) => (
                    <PricingRow
                        key={feature.key}
                        name={feature.key}
                        title={feature.title}
                        description={feature.description}
                        link={feature.link}
                        pricingPlansValues={feature.pricingPlansValues}
                        last={features.length - 1 === index}
                    />
                ))}
                {tryPricingPlanButtonsForGuest.length > 0 && (
                    <PricingRow
                        pricingPlansValues={tryPricingPlanButtonsForGuest}
                        last
                    />
                )}
            </div>
            <div className="d-block d-sm-none text-start">
                <PricingMobile
                    pricingPlans={pricingPlans}
                    features={features}
                    type={type}
                    tryGuestButton={tryMobileButtonForGuest}
                />
            </div>
        </>
    );
};

export default Pricing;
