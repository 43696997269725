import { CURRENCIES } from '../constants/currencies';
import { patchLocaleName } from './i18n';

export function getCurrencyForLocal(locale) {
    const currency = CURRENCIES.find((c) => c.defaultForLocales.includes(locale));
    const usd = CURRENCIES.find((c) => c.currency === 'USD');

    return currency ?? usd;
}

export function getCurrencyForCountry(country) {
    const currency = CURRENCIES.find((c) => c.countries.includes(country));
    const usd = CURRENCIES.find((c) => c.currency === 'USD');

    return currency ?? usd;
}

/**
 *
 * @param {number} price
 * @param {string} country
 * @param {string} locale
 * @returns {string}
 */
export function getPriceText(price, country, locale) {
    const currency = getCurrencyForCountry(country);
    const formattedPrice = Math.round(price * currency.exchangeRate).toLocaleString(patchLocaleName(locale));
    return currency.formatter.replace('{price}', formattedPrice);
}
