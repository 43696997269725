'use client';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';

import IconInline from 'components/icons/IconInline';
import PricingMobileRow from './PricingMobileRow';

function PricingMobilePlanFeatures({ pricingPlan }) {
    const t = useTranslations('content/pricing');
    const {
        title,
        previousPricingPlan,
        enabledFeaturesWithValue,
        featuresExistOnPreviousPricingPlan,
        extraFeaturesFromPreviousPricingPlan,
    } = pricingPlan;

    function renderFeatureRow(feature) {
        return (
            <PricingMobileRow
                key={`${title}-${feature.key}`}
                feature={feature}
            />
        );
    }

    if (!previousPricingPlan || featuresExistOnPreviousPricingPlan.length === 0) {
        return enabledFeaturesWithValue.map((feature) => renderFeatureRow(feature));
    }

    const PREVIEW_PREVIOUS_FEATURES = 3;
    const countMoreFeatures = featuresExistOnPreviousPricingPlan.length - PREVIEW_PREVIOUS_FEATURES;

    return (
        <>
            <div>
                <div className="mb-2 fw-bold">{t('site.pricing.featuresAllFrom')} {previousPricingPlan.title}:</div>
                {featuresExistOnPreviousPricingPlan
                    .slice(0, PREVIEW_PREVIOUS_FEATURES)
                    .map((feature) => renderFeatureRow(feature))}
                {countMoreFeatures > 0 && (
                    <>
                        <button
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-pricing-plan-${title}`}
                            aria-expanded="true"
                            // eslint-disable-next-line no-param-reassign
                            onClick={(event) => { event.currentTarget.style.display = 'none'; }}
                            className="border-0 link-primary shadow-none bg-white p-0"
                            style={{ width: 'fit-content' }}
                        >
                            {t('site.pricing.featuresAndMore', { count: countMoreFeatures })}
                            &nbsp;
                            <IconInline name="caret-down" />
                        </button>
                        <div
                            id={`collapse-pricing-plan-${title}`}
                            className={classNames('accordion-collapse collapse')}
                        >
                            {featuresExistOnPreviousPricingPlan
                                .slice(PREVIEW_PREVIOUS_FEATURES)
                                .map((feature) => renderFeatureRow(feature))}
                        </div>
                    </>
                )}
            </div>
            <div className="mt-5">
                <div className="mb-2 fw-bold">{t('site.pricing.featuresPlus')}:</div>
                {extraFeaturesFromPreviousPricingPlan.map((feature) => renderFeatureRow(feature))}
            </div>
        </>
    );
}

PricingMobilePlanFeatures.propTypes = {
    pricingPlan: PropTypes.shape({
        title: PropTypes.string,
        previousPricingPlan: PropTypes.shape({
            title: PropTypes.string,
        }),
        enabledFeaturesWithValue: PropTypes.array,
        featuresExistOnPreviousPricingPlan: PropTypes.array,
        extraFeaturesFromPreviousPricingPlan: PropTypes.array,
    }).isRequired,
};

export default PricingMobilePlanFeatures;
