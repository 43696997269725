'use client';

import { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocale, useTranslations } from 'next-intl';

import useCountry from '../../../hooks/useCountry';
import NameInput from '../../form/NameInput';
import PhoneInput from '../../form/PhoneInput';
import ReCAPTCHA from '../../form/ReCAPTCHA';
import Button from '../../UI/Button';
import { sendContactToCRM } from '../../../helpers/apiClient';
import { setCookie } from '../../../helpers/cookies';
import { openDemoAccount } from '../../../helpers/openExternal';

const whatsAppUrl = 'https://api.whatsapp.com/send/'
    + '?phone=%2B525585266685&text=Quiero+hablar+con+un+vendor.&app_absent=0';

const GatherContactModal = ({ show, onHide }) => {
    const country = useCountry();
    const locale = useLocale();
    const t = useTranslations('content/demo');
    const [phoneData, setPhoneData] = useState({});
    const [nameData, setNameData] = useState({});
    const [dontHaveWhatsApp, setDontHaveWhatsApp] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const recaptchaRef = useRef(null);

    const onButtonClick = async () => {
        setSubmitting(true);

        const token = await recaptchaRef.current.executeAsync();
        const answer = await sendContactToCRM({
            name: nameData.value,
            phone: phoneData.fullNumber,
            token,
            country,
            locale,
        });

        if (answer.error) {
            // show error
            return;
        }

        setCookie('demo_contact_form', 'true', 365, '/');
        openDemoAccount(locale);

        setSubmitting(false);

        onHide();
    };

    const showWhatsAppContactMessage = locale === 'mx' && !dontHaveWhatsApp;
    const formValid = phoneData.valid && nameData.valid;
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title className="mt-0">{ t('modal.demo.title') }</Modal.Title>
            </Modal.Header>

            { showWhatsAppContactMessage ? (
                <div className="px-6 text-center py-5">
                    <a className="btn btn-outline-primary" href={whatsAppUrl}>Hablar con un vendedor en WhatsApp</a>
                    <a className="btn btn-link mt-2 text-muted" onClick={() => setDontHaveWhatsApp(true)}>
                        No tengo WhatsApp
                    </a>
                </div>
            ) : (
                <>
                    <Modal.Body>
                        <p>{t('modal.demo.description')}</p>

                        <NameInput onChange={setNameData}/>
                        <PhoneInput onChange={setPhoneData}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className={!formValid ? 'disabled' : ''}
                            inputBlocked={!formValid}
                            onClick={onButtonClick}
                            inProgress={submitting}
                        >{t('modal.demo.submit')}</Button>
                    </Modal.Footer>
                </>
            )}
            <ReCAPTCHA ref={recaptchaRef}/>
        </Modal>
    );
};

export default GatherContactModal;
