'use client';

import classNames from 'classnames';
import IconInline from 'components/icons/IconInline';

function PricingMobileRowValue({ children }) {
    if (typeof children === 'boolean') {
        return (
            <span className={classNames('fs-6 me-1', {
                'text-success': children,
            })}>
                <IconInline name="check" />
            </span>
        );
    }

    return <b>{children}</b>;
}

export default PricingMobileRowValue;
