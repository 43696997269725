'use client';

import PropTypes from 'prop-types';

import Spinner from './Spinner';

/**
 * A button for main action
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Button(props) {
    const {
        className = '',
        children = '',
        outline = false,
        inputBlocked = false,
        inProgress = false,
        link,
        ...otherProps
    } = props;

    const btnClass = outline ? 'btn-outline-primary' : 'btn-primary';
    const primaryClassName = link ? 'btn-link' : btnClass;

    return (
        <button
            disabled={inputBlocked || inProgress}
            className={`btn ${primaryClassName} ${className}`}
            {...otherProps}
        >
            {inProgress && (<Spinner small className="me-2" color={outline ? 'gray' : 'white'}/>)}
            {children}
        </button>
    );
}

Button.propTypes = {
    inProgress: PropTypes.bool,
    outline: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any,
    inputBlocked: PropTypes.bool,
};
