'use client';

import { Accordion } from 'react-bootstrap';
import { useTranslations } from 'next-intl';

export default function BlogTOC(props) {
    const headings = JSON.parse(props.headings);
    const t = useTranslations('content/blog');

    return (
        <Accordion defaultActiveKey="-1" className="mb-5">
            <Accordion.Item eventKey="0" className="overflow-hidden">
                <Accordion.Header className="m-0">
                    {t('site.blogTOC.title')}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                    <div className="list-group list-group-flush">
                        {headings.map((heading) => (
                            <a
                                className="list-group-item list-group-item-action px-5"
                                key={heading.link}
                                href={heading.link}
                            >
                                {heading.title}
                            </a>
                        ))}
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}
